import React, { useContext, useEffect, useState } from 'react';

import { Drawer, Button, message, Typography, Divider, List } from 'antd';
import { parse, Node } from 'node-html-parser';
import ReactDOMServer from 'react-dom/server';

import AppendixPage from './AppendixPage';
import Page from './Page';
import { GlobalContext } from '../context/GlobalContextProvider';

const numToAlpha = (num: number) => {
    const alpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return alpha[num];
};

export const AddAppendixDrawer = ({
    visible,
    setVisibility,
}: {
    visible: boolean;
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const context = useContext(GlobalContext);

    const closeModal = () => {
        setVisibility(false);
    };

    const insertAppendix = async () => {
        const appendixNumber = context.html.match(/<strong>Appendix/gi)?.length || 0;
        const root = parse(context.html);
        const abstractPage = ReactDOMServer.renderToString(
            <Page id={`appendixPage-${numToAlpha(appendixNumber)}`}>
                <AppendixPage appendixNumber={appendixNumber} />
            </Page>,
        );
        const appendixPageNode = parse(abstractPage);
        const parent = root.firstChild;
        const childNodes = parent.childNodes as (Node & {
            rawTagName?: string;
            classNames?: string[];
            id?: string;
        })[];
        // Add appendix page at the last
        childNodes.push(...appendixPageNode.childNodes);
        context.setHtml(root.innerHTML);
        message.success(`Appendix ${numToAlpha(appendixNumber)} has been added`);
    };

    const getAppendicesNames = () => {
        const appendixNumber = context.html.match(/<strong>Appendix/gi)?.length || 0;
        const appendicesList = new Array(appendixNumber).fill(0).map((item, index) => `Appendix ${numToAlpha(index)}`);
        return appendicesList;
    };

    const removeAppendix = (appendixNumber: number) => {
        const root = parse(context.html);
        const parent = root.firstChild;
        const childNodes = parent.childNodes as (Node & {
            rawTagName?: string;
            classNames?: string[];
            id?: string;
        })[];
        // Remove appendix page
        const appendixPageNode = childNodes.find(
            node => node.rawTagName === 'div' && node.id === `appendixPage-${numToAlpha(appendixNumber)}`,
        );
        if (appendixPageNode) {
            childNodes.splice(childNodes.indexOf(appendixPageNode), 1);
            context.setHtml(root.innerHTML);
            message.success(`Appendix ${numToAlpha(appendixNumber)} has been removed`);
        }
    };

    const getAppendicesList = () => {
        const appendicesNames = getAppendicesNames();
        return (
            <List
                locale={{ emptyText: 'No appendices' }}
                dataSource={appendicesNames}
                renderItem={(name: string, index: number) => {
                    return (
                        <List.Item
                            extra={
                                index === appendicesNames.length - 1 ? (
                                    <Button type="link" size="small" danger onClick={() => removeAppendix(index)}>
                                        Remove
                                    </Button>
                                ) : (
                                    <></>
                                )
                            }
                        >
                            {name}
                        </List.Item>
                    );
                }}
            />
        );
    };

    return (
        <Drawer
            title="Insert Appendix"
            placement="right"
            onClose={closeModal}
            visible={visible}
            width="25vw"
            footer={null}
            destroyOnClose={true}
        >
            <Typography.Paragraph>
                Appendices are used to add supplementary material to your paper and are always added at the end of your
                paper.
            </Typography.Paragraph>
            <Typography.Paragraph>
                Appendix Titles should describe the content contained in the appendix and should use title case
                capitalization (capitalizing all major words).
            </Typography.Paragraph>

            <Button type="primary" block onClick={() => insertAppendix()}>
                Insert Appendix
            </Button>

            <Divider />

            {getAppendicesList()}

            <Divider />

            <Typography.Text type="secondary">
                <p>
                    Appendices are labeled based on the number of appendices in the paper. A single appendix is labeled
                    “Appendix,” while multiple appendices are labeled “Appendix A,” “Appendix B,” etc. We handle the
                    appendix labels for you.
                </p>
                <p>
                    If you delete appendices or refresh their labels, be sure any appendix call outs in the body of your
                    paper match the proper appendix.
                </p>
            </Typography.Text>
        </Drawer>
    );
};

export default AddAppendixDrawer;
