import React from 'react';

export const ReferencesPage = () => {
    return (
        <>
            <div
                id="referencePage"
                className="referencePage mceNonEditable"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    outline: 'none',
                    lineHeight: 2,
                }}
            >
                <h1 className="mceNonEditable">References</h1>

                <div className="mceNonEditable" style={{ flexGrow: 1, outline: 'none' }} id="referenceList"></div>
            </div>
            <div
                className="page-break mceNonEditable"
                style={{ overflow: 'hidden', height: 0, pageBreakAfter: 'always' }}
            />
        </>
    );
};

export default ReferencesPage;
