import React from 'react';

export const TableOfContentsPage = () => {
    return (
        <>
            <div
                id="tableOfContents"
                className="tableOfContentsPage"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    outline: 'none',
                    lineHeight: 2,
                }}
            >
                <p className="mceNonEditable text-center no-indent" style={{ textAlign: 'center', lineHeight: 2 }}>
                    <strong>Table of Contents</strong>
                </p>

                <div className="mceNonEditable" style={{ flexGrow: 1, outline: 'none', lineHeight: 2 }}>
                    <table
                        id="tableOfContentsTable"
                        style={{ width: '100%', tableLayout: 'fixed' }}
                        className="no-border"
                    ></table>
                </div>
            </div>
            <div
                className="page-break mceNonEditable"
                style={{ overflow: 'hidden', height: 0, pageBreakAfter: 'always' }}
            />
        </>
    );
};

export default TableOfContentsPage;
