import React, { useContext, useState } from 'react';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Drawer, List } from 'antd';

// https://www.npmjs.com/package/pdfreader

import Reference from './Reference';
import { GlobalContext } from '../context/GlobalContextProvider';
import { useReferences } from '../hooks';

/* eslint-disable @typescript-eslint/no-var-requires */

export const CitationDrawer = ({
    visible,
    setVisibility,
}: {
    visible: boolean;
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const context = useContext(GlobalContext);
    const { references, removeReference, setActiveReference } = useReferences();
    const [showEditReference, setShowEditReference] = useState(false);

    const closeModal = () => {
        setVisibility(false);
    };

    return (
        <Drawer title="Citations" placement="right" onClose={closeModal} visible={visible} width="25vw" mask={false}>
            <List
                dataSource={references}
                renderItem={(reference: AutoAPA.Reference) => {
                    /* eslint-disable react/prop-types */
                    const rawReference = reference?.rawReference;
                    return (
                        <List.Item
                            actions={[
                                rawReference ? (
                                    <></>
                                ) : (
                                    <Button
                                        key="edit-reference-btn"
                                        icon={<EditOutlined />}
                                        onClick={() => {
                                            setActiveReference(reference);
                                            setShowEditReference(true);
                                        }}
                                        shape="circle"
                                    />
                                ),
                                <Button
                                    key="delete-reference-btn"
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() => removeReference(reference)}
                                    shape="circle"
                                />,
                            ]}
                        >
                            <Reference reference={reference} />
                        </List.Item>
                    );
                }}
            />
        </Drawer>
    );
};

export default CitationDrawer;
