import React, { useContext } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import parse from 'node-html-parser';

import { GlobalContext } from '../context/GlobalContextProvider';
import { useReferences } from '../hooks';

const Reference = ({
    reference,
    showAddCitation = true,
}: {
    reference: AutoAPA.Reference;
    showAddCitation?: boolean;
}) => {
    const context = useContext(GlobalContext);
    const { generateCitationString } = useReferences();
    const addCitation = (reference: AutoAPA.Reference) => {
        const citationHtml = reference.rawCitation || generateCitationString(reference);
        const cursorPositionEle = context.editor.current?.selection?.getNode();
        const html = context.html;
        const root = parse(html);
        let pageElement: any;
        if (cursorPositionEle?.id === 'tinymce' || cursorPositionEle?.parentElement?.id === 'tinymce') {
            pageElement = root.querySelector(`.contentPage`);
            const firstDiv = pageElement.childNodes[3];
            if (firstDiv) {
                firstDiv.innerHTML = firstDiv.innerHTML + `<p>${citationHtml}</p>`;
            }
            context.setHtml(root.innerHTML);
        } else {
            context.editor.current?.insertContent(citationHtml);
        }
    };

    return (
        <Space direction="vertical">
            <div dangerouslySetInnerHTML={{ __html: reference.rawReference! }} />
            {showAddCitation ? (
                <Button icon={<PlusOutlined />} type="link" onClick={() => addCitation(reference)}>
                    Add Citation
                </Button>
            ) : null}
        </Space>
    );
};

export default Reference;
