import React, { useContext, useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Drawer, Form, Input, Button, message, Typography, Radio, Upload } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import imageToBase64 from 'image-to-base64/browser';

import { GlobalContext } from '../context/GlobalContextProvider';

export const AddFigureDrawer = ({
    visible,
    setVisibility,
}: {
    visible: boolean;
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [form] = Form.useForm();
    const context = useContext(GlobalContext);
    const [source, setSource] = useState<undefined | 'upload' | 'url'>();
    const [url, setUrl] = useState<string>();

    const closeModal = () => {
        setVisibility(false);
    };

    const insertFigure = async () => {
        const formValues = form.getFieldsValue();

        let imageSrc = url;
        if (source === 'url') imageSrc = `data:image/png;base64,${await imageToBase64(url)}`;

        message.success('Figure has been inserted');
        setVisibility(false);
        form.resetFields();
        setUrl(undefined);

        formValues.notes
            ? context.editor.current?.insertContent(
                  `<div id="replace-figure-id">Figure #</div><div>${formValues.title}</div><br/><img  src="${imageSrc}" style="max-width: 100%; display: block;"><i>Note: ${formValues.notes}</i><div></div>`,
              )
            : context.editor.current?.insertContent(
                  `<div id="replace-figure-id">Figure #</div><div>${formValues.title}</div><br/><img src="${imageSrc}" style="max-width: 100%; display: block;"><div></div>`,
              );
    };

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const getBase64 = (file: RcFile): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const handleChange = (info: UploadChangeParam) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj!).then(setUrl);
        }
    };

    useEffect(() => {
        form.resetFields();
        setUrl(undefined);
        setSource(undefined);
    }, []);

    return (
        <Drawer
            title="Insert Figure"
            placement="right"
            onClose={closeModal}
            visible={visible}
            width="25vw"
            footer={
                <Button type="primary" block onClick={() => form.submit()}>
                    Insert Figure
                </Button>
            }
            destroyOnClose={true}
        >
            <Typography.Paragraph>
                Figures help illustration ideas in your paper. They can be photographs, charts, or other images.
            </Typography.Paragraph>
            <Typography.Paragraph>
                Place your cursor where you want us to insert the figure. Then select the Figure file and add a caption
                below. We will create the formatting and manage the figure number for you.
            </Typography.Paragraph>

            <Form form={form} labelCol={{ span: 24 }} onFinish={insertFigure}>
                <Form.Item
                    name="title"
                    label="Figure Title"
                    extra="Titles should be concise and use Title Case capitalization."
                    rules={[{ required: true, message: 'Please input the title of the figure' }]}
                >
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="figureSource" label="Select the figure image">
                    <Radio.Group
                        onChange={e => {
                            setSource(e.target.value);
                        }}
                    >
                        <Radio value="upload">Image on my computer</Radio>
                        <Radio value="url">Image from a URL</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    hidden={!source || source !== 'upload'}
                    name="upload"
                    label="Upload figure"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                    <Upload
                        name="logo"
                        multiple={false}
                        onChange={handleChange}
                        /* @ts-ignore disable-next-line */
                        method="get"
                        onRemove={() => setUrl(undefined)}
                    >
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                </Form.Item>
                <Form.Item hidden={!source || source !== 'url'} label="Enter image URL" name="url">
                    <Input allowClear onChange={e => setUrl(e.target.value)} />
                </Form.Item>
                <Form.Item
                    name="notes"
                    label="Figure notes (optional)"
                    extra="Figure notes are used to clarify information in your figure."
                >
                    <Input allowClear />
                </Form.Item>
                {url && (
                    <>
                        <Typography.Title level={5}>Preview</Typography.Title>
                        <img src={url} alt="Figure" style={{ width: '100%' }} />
                    </>
                )}
            </Form>
        </Drawer>
    );
};

export default AddFigureDrawer;
