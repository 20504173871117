import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
    ExportOutlined,
    FontSizeOutlined,
    SolutionOutlined,
    HomeOutlined,
    PictureOutlined,
    TableOutlined,
    FormOutlined,
    PaperClipOutlined,
    OrderedListOutlined,
    BookOutlined,
    UserOutlined,
    LogoutOutlined,
    SaveOutlined,
    BarsOutlined,
    ShrinkOutlined,
    LinkOutlined,
    RobotOutlined,
    FileTextOutlined,
    FileSearchOutlined,
    CommentOutlined,
    ArrowsAltOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Drawer, Spin, Divider, Dropdown, Tag, Tooltip } from 'antd';
import { AxiosResponse } from 'axios';
import { navigate } from 'gatsby';
import h2p from 'html2plaintext';
import parse from 'node-html-parser';
import ReactDOMServer from 'react-dom/server';
import store from 'store';

import AbstractPage from '../components/AbstractPage';
import AbstractPageDrawer from '../components/AbstractPageDrawer';
import AddAppendixDrawer from '../components/AddAppendixDrawer';
import AddFigureDrawer from '../components/AddFigureDrawer';
import AddTableDrawer from '../components/AddTableDrawer';
import { AutoCheckDrawer } from '../components/AutoCheckDrawer';
import { CitationDrawer } from '../components/CitationsDrawer';
import ConclusionPage from '../components/ConclusionPage';
import ConclusionPageDrawer from '../components/ConclusionPageDrawer';
import ContentPage from '../components/ContentPage';
import Editor from '../components/Editor';
import ExportModal from '../components/Export';
import GenerateTextDrawer from '../components/GenerateTextDrawer';
import Head from '../components/Head';
import Notifications from '../components/NotificationBell';
import Page from '../components/Page';
import ReferencePageDrawer from '../components/ReferencePageDrawer';
import ReferencesPage from '../components/ReferencesPage';
import SharePaperModal from '../components/SharePaperModal';
import TableOfContentsPageDrawer from '../components/TableOfContentsPageDrawer';
import TitlePage from '../components/TitlePage';
import TitlePageDrawer from '../components/TitlePageDrawer';
import { GlobalContext } from '../context/GlobalContextProvider';
import { useAuth, usePaper } from '../hooks';
import { useReferences } from '../hooks';
import {
    createPaper,
    handleSuccess,
    apiRequester,
    handleError,
    referenceToBibTex,
    reportInProgress,
    showNotification,
} from '../utility';

const { Header, Sider, Content } = Layout;

export const EditorPage = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [showTitlePageDrawer, setShowTitlePageDrawer] = useState(false);
    const [showAbstractPageDrawer, setShowAbstractPageDrawer] = useState(false);
    const [showConclusionPageDrawer, setShowConclusionPageDrawer] = useState(false);
    const [showTableOfContentsPageDrawer, setShowTableOfContentsPageDrawer] = useState(false);
    const [showAddFigureDrawer, setShowAddFigureDrawer] = useState(false);
    const [showAddTableDrawer, setShowAddTableDrawer] = useState(false);
    const [showAddAppendixDrawer, setShowAddAppendixDrawer] = useState(false);
    const [showReferenceDrawer, setShowReferenceDrawer] = useState(false);
    const [showGenerateTextDrawer, setShowGenerateTextDrawer] = useState(false);
    const [exportModalVisible, setExportModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const { loggedInUser, logout, localLoginCheck } = useAuth();
    const { savePaper, paperLoading, loadPaper, activePaper, updateTitlePage } = usePaper();
    const [showCitationDrawer, setShowcitationDrawer] = useState(false);
    const [generateTextDrawerData, setGenerateTextDrawerData] = useState({});
    const [generateDrawerMinimise, setGenerateDrawerMinimise] = useState(false);
    const context = useContext(GlobalContext);
    const { convertBibTexToCitation, convertBibTexToReference, addReference, refreshReferencesPage } = useReferences();

    let queryParams = new URLSearchParams('');
    const isBrowser = () => typeof window !== 'undefined';
    if (isBrowser() && window.location && window.location.search) {
        queryParams = new URLSearchParams(window.location.search);
    }

    useEffect(() => {
        if (!loading && queryParams.get('token')) {
            setShowGenerateTextDrawer(true);
            refreshReferencesPage();
        }
    }, [loading]);

    const onCollapse = (collapsed: boolean) => {
        setCollapsed(collapsed);
    };

    const showExportModal = () => {
        if (context.performedSystemCheck) {
            context.setShowExportModal(true);
        } else {
            context.setShowReferenceCheckDrawer(true);
            context.setSystemCheckStack('export');
        }
    };

    const addReferencesToPage = async (references: string[]) => {
        const promises = [];
        for (let i = 0; i < references.length; i++) {
            const plainReference = references[i];
            const bibTexResponse = await referenceToBibTex(plainReference!);
            const bibTex = bibTexResponse.output?.split('\n\n')[0];
            const reference = convertBibTexToReference(bibTex!);
            const inTextCitation = await convertBibTexToCitation(bibTex!);

            promises.push(
                addReference({ rawCitation: inTextCitation, rawReference: reference, bibTex, source: 'manual' }),
            );
        }

        Promise.allSettled(promises).then(resp => {
            setLoading(false);
        });
    };

    const fetchGeneratedTextDetails = async (token: string) => {
        try {
            const res = await apiRequester.getGeneratedTextData(token);
            handleSuccess('Generated text details fetched successfully');
            setGenerateTextDrawerData({
                generatedText: res.data.generatedText,
                keyword: res.data.keyword,
                phrase: res.data.prompt,
                subject: res.data.subject,
            });
            const references = res.data.references;
            addReferencesToPage(references);
        } catch (err) {
            handleError(err);
        } finally {
        }
    };
    const createAndSetPaper = async (paper: Papers.Paper, token: string) => {
        try {
            setLoading(true);
            const newPaper = await apiRequester.createPaper(paper);
            handleSuccess('Paper has been created');
            context.setActivePaper(newPaper);
            store.set('activePaper', newPaper);

            fetchGeneratedTextDetails(token!);
        } catch (err) {
            handleError(err);
        }
    };
    useEffect(() => {
        // sample url with token -> http://localhost:8000/editor?token=abcdef
        //create paper with paper details
        if (queryParams.get('token')) createAndSetPaper({ type: 'research' }, queryParams.get('token')!);
    }, []);

    useEffect(() => {
        localLoginCheck();
    }, []);

    useEffect(() => {
        if (activePaper) {
            const { rawHtml, type } = activePaper;
            if (!rawHtml) {
                if (type === 'research') {
                    context.setHtml(
                        ReactDOMServer.renderToString(
                            <div className="mceNonEditable">
                                <Page id="titlePage">
                                    <TitlePage {...activePaper} />
                                </Page>
                                <Page id="abstractPage">
                                    <AbstractPage />
                                </Page>
                                <Page id="contentPage">
                                    <ContentPage title={activePaper.title} />
                                </Page>
                                <Page id="conclusionPage">
                                    <ConclusionPage />
                                </Page>
                                <Page id="referencesPage">
                                    <ReferencesPage />
                                </Page>
                            </div>,
                        ),
                    );
                } else if (type === 'assignment') {
                    context.setHtml(
                        ReactDOMServer.renderToString(
                            <div className="mceNonEditable">
                                <Page id="titlePage">
                                    <TitlePage {...activePaper} />
                                </Page>
                                <Page id="contentPage">
                                    <ContentPage title={activePaper.title} />
                                </Page>
                                <Page id="referencesPage">
                                    <ReferencesPage />
                                </Page>
                            </div>,
                        ),
                    );
                } else if (type === 'discussion') {
                    context.setHtml(
                        ReactDOMServer.renderToString(
                            <div className="mceNonEditable">
                                <Page id="contentPage">
                                    <ContentPage title={activePaper.title} integrateReferences={true} />
                                </Page>
                            </div>,
                        ),
                    );
                }
            } else {
                context.setHtml(() => {
                    const root = parse(rawHtml);
                    const spans = root.querySelectorAll('span');
                    spans.forEach(span => {
                        const className = span.classNames;
                        if (className === 'highlight_invalid_between' || className === 'highlight_invalid_start') {
                            span.replaceWith(span.innerText);
                        }
                    });
                    return root.toString();
                });
            }
        }
    }, [activePaper]);

    useEffect(() => {
        context.editorLoaded && context.activePaper?._id && loadPaper();
    }, [context.activePaper?._id, context.editorLoaded]);

    function getPaperContents(): string {
        const html = context.html;
        const root = parse(html);
        let content = '';
        const abstractPage = root.querySelector('.abstractPage');
        const conclusionPage = root.querySelector('.conclusionPage');
        const contentPage = root.querySelector('.contentPage');
        if (abstractPage) {
            content += h2p(abstractPage) + '\n';
        }
        if (contentPage) {
            if (activePaper?.type === 'discussion') {
                const allChildNodes = contentPage.childNodes;
                if (allChildNodes.length) {
                    content +=
                        allChildNodes?.[1]?.innerText + allChildNodes?.[2]?.innerText + allChildNodes?.[3]?.innerText;
                }
            } else {
                content += h2p(contentPage);
            }
        }
        if (conclusionPage) {
            content += '\n' + h2p(conclusionPage);
        }

        return content;
    }

    function getReport() {
        return context.plagarismReports.find(
            report => report.paper_id === activePaper?._id && report.status === 'fulfilled',
        );
    }

    function plagarismCheckCompleted() {
        const report = getReport();
        const reportUrl = activePaper?.plagarism_report_url;
        return !!report || reportUrl;
    }

    useEffect(() => {
        const periodicSaver = setInterval(() => {
            savePaper();
        }, 60000);

        return () => {
            clearInterval(periodicSaver);
        };
    }, [context.html, context.titlePage]);

    return (
        <Layout style={{ height: '100vh' }}>
            <Head title="Editor" />
            <Spin spinning={loading}>
                <Header
                    style={{
                        padding: '0 0',
                        paddingRight: '1.5rem',
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        background: '#1f1e37',
                        position: 'sticky',
                        top: 0,
                        width: '100%',
                        left: 0,
                        right: 0,
                        zIndex: 1000,
                    }}
                >
                    <Menu theme="dark" mode="horizontal" style={{ background: '#1f1e37', flexGrow: 1 }}>
                        <Menu.Item disabled key="logo">
                            <div
                                style={{
                                    height: '64px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem',
                                }}
                            >
                                {collapsed ? 'APA' : 'AutoAPA'}
                            </div>
                        </Menu.Item>
                        <Menu.Item key="home" icon={<HomeOutlined />} onClick={() => navigate('/')}>
                            Home
                        </Menu.Item>
                        <Menu.Item key="export" icon={<ExportOutlined />} onClick={showExportModal}>
                            Export
                        </Menu.Item>
                        <Menu.Item
                            key="save"
                            icon={paperLoading ? <SaveOutlined spin={true} /> : <SaveOutlined />}
                            onClick={() => {
                                savePaper();
                                handleSuccess('Paper has been saved');
                            }}
                        >
                            Save
                        </Menu.Item>
                        {/* <Menu.Item
                            key="share"
                            icon={<LinkOutlined />}
                            onClick={() => {
                                await savePaper();
                                if (context.performedSystemCheck) {
                                    context.setSharePaper(context.activePaper);
                                } else {
                                    context.setShowReferenceCheckDrawer(true);
                                    context.setSystemCheckStack('share');
                                }
                            }}
                        >
                            Share
                        </Menu.Item> */}

                        <Tooltip
                            placement="topLeft"
                            title={
                                !!reportInProgress(context.plagarismReports, context.activePaper?._id)
                                    ? 'Plagarism Check in progress'
                                    : ''
                            }
                        >
                            <Menu.Item
                                key="plagarism"
                                style={{
                                    color: 'rgba(255,255,255,0.65',
                                    cursor: !!reportInProgress(context.plagarismReports, context.activePaper?._id)
                                        ? 'not-allowed'
                                        : 'pointer',
                                }}
                                // disabled={!!reportInProgress(context.plagarismReports, context.activePaper?._id)}
                                icon={<FileSearchOutlined />}
                                onClick={() => {
                                    if (!!!reportInProgress(context.plagarismReports, context.activePaper?._id)) {
                                        handleSuccess(
                                            'You will get a notification once the plagarism check is completed.',
                                        );
                                        context.setPlagarismReports([
                                            ...context.plagarismReports,
                                            {
                                                paper_id: context.activePaper?._id,
                                                name: context.activePaper?.title,
                                                status: 'pending',
                                            },
                                        ]);
                                        const plainText = getPaperContents();
                                        console.log('Active Paper is', context.activePaper);

                                        // eslint-disable-next-line prettier/prettier
                                        const palagarismPromise: Promise<AxiosResponse<any>> =
                                            apiRequester.checkPlagarism(activePaper?._id!, plainText);
                                        context.setPlagarismReportsPromises([
                                            ...context.plagarismReportsPromises,
                                            palagarismPromise,
                                        ]);
                                        palagarismPromise
                                            .then((resp: any) => {
                                                const palagarismReports = context.plagarismReports;
                                                palagarismReports.push(resp);
                                                context.setPlagarismReports(palagarismReports);
                                            })
                                            .catch(err => {
                                                showNotification(
                                                    'error',
                                                    `Error encountered while performing plagarism check.`,
                                                );
                                            });
                                    }
                                }}
                            >
                                Plagarism Check
                            </Menu.Item>
                        </Tooltip>
                    </Menu>
                    <Notifications style={{ marginRight: 20 }} iconStyle={{ color: 'white' }} />

                    <Dropdown
                        overlay={
                            <>
                                <Menu>
                                    <Menu.Item icon={<UserOutlined />}>
                                        <>
                                            {loggedInUser?.firstName || ''} {loggedInUser?.lastName || ''}{' '}
                                            {loggedInUser?.roles &&
                                                loggedInUser?.roles.length &&
                                                loggedInUser?.roles[0] === 'admin' && <Tag color="red">Admin</Tag>}
                                            {loggedInUser?.roles &&
                                                loggedInUser?.roles.length &&
                                                loggedInUser?.roles[0] === 'student' && <Tag color="blue">Student</Tag>}
                                        </>
                                    </Menu.Item>
                                    <Menu.Item onClick={logout} icon={<LogoutOutlined />}>
                                        Logout
                                    </Menu.Item>
                                </Menu>
                            </>
                        }
                    >
                        <UserOutlined style={{ color: 'white' }} />
                    </Dropdown>
                </Header>
                <Layout>
                    <Layout>
                        <Content>
                            <Editor setGlobalLoading={queryParams.get('token') ? null : setLoading} />
                        </Content>
                    </Layout>
                    <Sider
                        collapsible
                        collapsed={collapsed}
                        onCollapse={onCollapse}
                        theme="light"
                        reverseArrow={true}
                        collapsedWidth="50px"
                    >
                        <Menu theme="light" mode="inline" selectable={false}>
                            <Menu.Item
                                key="title-page"
                                icon={<FontSizeOutlined />}
                                onClick={() => setShowTitlePageDrawer(true)}
                            >
                                Title Page
                            </Menu.Item>
                            <Menu.Item
                                key="abstract-page"
                                icon={<SolutionOutlined />}
                                onClick={() => setShowAbstractPageDrawer(true)}
                            >
                                Abstract
                            </Menu.Item>
                            {activePaper?.type !== 'discussion' && activePaper?.type !== 'assignment' && (
                                <Menu.Item
                                    key="tableOfContents-page"
                                    icon={<OrderedListOutlined />}
                                    onClick={() => setShowTableOfContentsPageDrawer(true)}
                                >
                                    Table of Contents
                                </Menu.Item>
                            )}
                            {activePaper?.type !== 'discussion' && activePaper?.type !== 'assignment' && (
                                <Menu.Item
                                    key="conclusion-page"
                                    icon={<ShrinkOutlined />}
                                    onClick={() => setShowConclusionPageDrawer(true)}
                                >
                                    Conclusion
                                </Menu.Item>
                            )}
                            <Menu.Item
                                key="references"
                                icon={<BookOutlined />}
                                onClick={() => setShowReferenceDrawer(true)}
                            >
                                References
                            </Menu.Item>
                            <Menu.Item
                                key="citations"
                                icon={<CommentOutlined />}
                                onClick={() => setShowcitationDrawer(true)}
                            >
                                Citations
                            </Menu.Item>
                            {activePaper?.type !== 'discussion' && activePaper?.type !== 'assignment' && (
                                <Menu.Item
                                    key="appendix-page"
                                    icon={<PaperClipOutlined />}
                                    onClick={() => setShowAddAppendixDrawer(true)}
                                >
                                    Appendix
                                </Menu.Item>
                            )}
                            <Menu.Item
                                key="references"
                                icon={<FileTextOutlined />}
                                onClick={() => setShowGenerateTextDrawer(true)}
                                style={{ pointerEvents: generateDrawerMinimise ? 'none' : 'auto' }}
                            >
                                Generate Text
                                {generateDrawerMinimise && (
                                    <Tooltip placement="bottom" title={'maximise'}>
                                        <ArrowsAltOutlined
                                            style={{ marginLeft: '10px', pointerEvents: 'auto' }}
                                            onClick={() => {
                                                setShowGenerateTextDrawer(true);
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </Menu.Item>
                            <Menu.Item key="divider">
                                <Divider />
                            </Menu.Item>
                            {/* {activePaper?.type !== 'discussion' && activePaper?.type !== 'assignment' && (
                                <Menu.Item
                                    key="add-figure"
                                    icon={<PictureOutlined />}
                                    onClick={() => setShowAddFigureDrawer(true)}
                                >
                                    Add Figure
                                </Menu.Item>
                            )} */}
                            {activePaper?.type !== 'discussion' && activePaper?.type !== 'assignment' && (
                                <Menu.Item
                                    key="add-table"
                                    icon={<TableOutlined />}
                                    onClick={() => {
                                        const parent = context?.editor?.current?.selection?.getNode().parentElement;
                                        const grandparent =
                                            context?.editor?.current?.selection?.getNode().parentElement?.parentElement;
                                        if (
                                            parent?.tagName?.toLowerCase() === 'td' ||
                                            grandparent?.tagName?.toLowerCase() === 'td'
                                        ) {
                                            handleError({ message: 'Cannot add nested tables' });
                                        } else {
                                            setShowAddTableDrawer(true);
                                        }
                                    }}
                                >
                                    Add Table
                                </Menu.Item>
                            )}{' '}
                            {plagarismCheckCompleted() ? (
                                <Menu.Item key="tableOfContents-page" icon={<FileSearchOutlined />}>
                                    <a
                                        href={activePaper?.plagarism_report_url || getReport()?.reportUrl}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Plagarism Report
                                    </a>
                                </Menu.Item>
                            ) : null}
                            <Menu.Item
                                icon={<RobotOutlined />}
                                onClick={() => {
                                    context.setSystemCheckStack('');
                                    context.setShowReferenceCheckDrawer(true);
                                }}
                            >
                                System Checks
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <TitlePageDrawer visible={showTitlePageDrawer} setVisibility={setShowTitlePageDrawer} />
                    <AbstractPageDrawer visible={showAbstractPageDrawer} setVisibility={setShowAbstractPageDrawer} />
                    <ConclusionPageDrawer
                        visible={showConclusionPageDrawer}
                        setVisibility={setShowConclusionPageDrawer}
                    />
                    <TableOfContentsPageDrawer
                        visible={showTableOfContentsPageDrawer}
                        setVisibility={setShowTableOfContentsPageDrawer}
                    />
                    <AddFigureDrawer visible={showAddFigureDrawer} setVisibility={setShowAddFigureDrawer} />
                    <AddTableDrawer visible={showAddTableDrawer} setVisibility={setShowAddTableDrawer} />
                    <AddAppendixDrawer visible={showAddAppendixDrawer} setVisibility={setShowAddAppendixDrawer} />
                    <ReferencePageDrawer visible={showReferenceDrawer} setVisibility={setShowReferenceDrawer} />
                    <GenerateTextDrawer
                        visible={showGenerateTextDrawer}
                        setVisibility={setShowGenerateTextDrawer}
                        generateTextDrawerData={generateTextDrawerData}
                        editorLoading={loading}
                        setGenerateDrawerMinimise={setGenerateDrawerMinimise}
                    ></GenerateTextDrawer>
                    <CitationDrawer visible={showCitationDrawer} setVisibility={setShowcitationDrawer} />
                </Layout>
                <ExportModal visible={context.showExportModal} />
                <SharePaperModal />
                <AutoCheckDrawer />
            </Spin>
        </Layout>
    );
};

export default EditorPage;
