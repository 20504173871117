/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useState, useEffect, useContext } from 'react';

import { DownOutlined, UserOutlined, MinusOutlined } from '@ant-design/icons';
import { Drawer, Form, Input, Button, Select, Tooltip, Space, Dropdown, Typography, Row, Col } from 'antd';
import { MenuProps, message } from 'antd';
import parse from 'node-html-parser';

import { GlobalContext } from '../context/GlobalContextProvider';
import {
    apiRequester,
    getAllSubjects,
    getRandom,
    getRecommendedSubjects,
    getSuggestions,
    handleError,
} from '../utility';

const GenerateTextDrawer = ({
    visible,
    setVisibility,
    generateTextDrawerData,
    editorLoading,
    setGenerateDrawerMinimise,
}: {
    visible: boolean;
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    generateTextDrawerData: { keyword?: string; phrase?: string; subject?: string; generatedText?: string };
    editorLoading: boolean;
    setGenerateDrawerMinimise: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [form] = Form.useForm();
    const context = useContext(GlobalContext);
    const [keyword, setKeyword] = useState<string | undefined>('');
    const [phrase, setPhrase] = useState<string>('');
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [textLoading, setTextLoading] = useState<boolean>(false);
    const [continueLoading, setContinueLoading] = useState<boolean>(false);
    const [generatedText, setGeneratedText] = useState<{ text: string; wordcount: number }>();
    const [subjectSelectedIndex, setSubjectSelectedIndex] = useState<string>('');
    type MenuItems = any;

    const recommendations = getRecommendedSubjects().map((recommend, index) => {
        return {
            label: recommend,
            key: `${index + 1}`,
            icon: <UserOutlined />,
        };
    });

    const allSubjects = getAllSubjects().map((recommend, index) => {
        return {
            label: recommend,
            key: `${recommendations.length + 3 + index}`,
            icon: <UserOutlined />,
        };
    });

    const items: Array<MenuItems> = [
        {
            label: 'Recommended Subjects',
            disabled: true,
            icon: null,
        },
        ...recommendations,

        { type: 'divider' },
        {
            key: `${recommendations.length + 1}`,
            label: 'All Subjects',
            disabled: true,
        },
        ...allSubjects,
    ];
    useEffect(() => {
        if (generateTextDrawerData) {
            setKeyword(generateTextDrawerData.keyword);
            setPhrase(generateTextDrawerData.phrase!);
            setGeneratedText({ text: generateTextDrawerData.generatedText!, wordcount: 0 });
            const subjectsArr = items.filter(
                item => item?.label?.toLowerCase() === generateTextDrawerData.subject?.toLowerCase(),
            );
            setSubjectSelectedIndex(subjectsArr ? subjectsArr[0]?.key : '');
        }
    }, [generateTextDrawerData]);
    useEffect(() => {
        if (Object.keys(generateTextDrawerData).length > 0 && !editorLoading) {
            window.history.replaceState(null, '', '/editor');
            setVisibility(true);
        }
    }, [editorLoading]);
    const fetchText = async (phrase: string, subject: string, continueGen?: boolean) => {
        try {
            if (continueGen) setContinueLoading(true);
            else setTextLoading(true);

            if (phrase && subject && context?.loggedInUser) {
                const res = await apiRequester.generateText(
                    phrase,
                    subject,
                    context.loggedInUser?.emailId!,
                    context.loggedInUser?.firstName!,
                );
                setGeneratedText(res);
            }
            if (!subject) {
                message.config({ top: 700 });
                message.info('Please select a subject!');
            }
        } catch (error) {
            handleError(error);
        } finally {
            setTextLoading(false);
            setContinueLoading(false);
        }
    };
    const handleMenuClick: MenuProps['onClick'] = e => {
        const index = Number(e.key);
        setSubjectSelectedIndex(e.key);
        fetchText(phrase, items[index]?.label);
    };
    const menuProps = {
        items,
        onClick: handleMenuClick,
        selectable: true,
        defaultSelectedKeys: [subjectSelectedIndex],
        disabled: phrase ? false : true,
    };
    const addTextToPage = (e: React.MouseEvent<HTMLInputElement>) => {
        const selectedText = window.getSelection()?.toString();
        const btnValue = e.currentTarget.value;
        const cursorPositionEle = context.editor.current?.selection?.getNode();
        const html = context.html;
        const root = parse(html);
        let pageElement: any;
        if (btnValue === 'paper') {
            if (cursorPositionEle?.id === 'tinymce' || cursorPositionEle?.parentElement?.id === 'tinymce') {
                pageElement = root.querySelector(`.contentPage`);
                const firstDiv = pageElement.childNodes[3];
                if (firstDiv) {
                    firstDiv.innerHTML =
                        firstDiv.innerHTML + `<p>${selectedText ? selectedText : generatedText?.text}</p>`;
                }
                context.setHtml(root.innerHTML);
            } else {
                context.editor.current?.insertContent(selectedText ? selectedText : generatedText?.text!);
            }
            return;
        } else if (btnValue === 'content') {
            pageElement = root.querySelector(`.contentPage`);
        } else if (btnValue === 'abstract') {
            pageElement = root.querySelector(`.abstractPage`);
        } else if (btnValue === 'conclusion') {
            pageElement = root.querySelector(`.conclusionPage`);
        }
        const firstDiv = pageElement.childNodes[3];
        if (firstDiv) {
            firstDiv.innerHTML = firstDiv.innerHTML + `<p>${selectedText ? selectedText : generatedText?.text}</p>`;
        }
        context.setHtml(root.innerHTML);
    };
    const addSuggestions = () => {
        //api call to fetch suggestions if keyword!==""
        //set suggestions
        if (keyword) {
            const suggestions = getSuggestions(keyword);
            const randomSuggestions = getRandom(suggestions, 5);
            setSuggestions(randomSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const getGenerateButtonString = () => {
        const buttonName = subjectSelectedIndex ? items[Number(subjectSelectedIndex)]?.label : 'Generate';
        return buttonName.length <= 8 ? buttonName : buttonName.substring(0, 6) + '...';
    };

    return (
        <Drawer
            title="Generate Text"
            placement="bottom"
            onClose={() => {
                setVisibility(false);
                setGenerateDrawerMinimise(false);
                setKeyword('');
                setPhrase('');
                setGeneratedText(undefined);
                setSuggestions([]);
            }}
            visible={visible}
            width={'75vw'}
            destroyOnClose={true}
            mask={false}
            extra={
                <Space>
                    <Button
                        onClick={() => {
                            setVisibility(false);
                            setGenerateDrawerMinimise(true);
                        }}
                    >
                        <MinusOutlined />
                    </Button>
                </Space>
            }
        >
            <Form
                form={form}
                labelCol={{ span: 24 }}
                onFinish={() => {
                    addSuggestions();
                    fetchText(phrase, subjectSelectedIndex ? items[Number(subjectSelectedIndex)]?.label : '');
                }}
                layout="inline"
                style={{ width: '100%' }}
            >
                <Row gutter={[16, 16]} style={{ width: '100%' }}>
                    <Col span={6}>
                        <Input
                            onChange={e => {
                                setKeyword(e.target.value);
                                if (e.target.value === '') setSuggestions([]);
                            }}
                            value={keyword}
                            placeholder="Keyword"
                            onBlur={addSuggestions}
                        />
                    </Col>
                    <Col span={14}>
                        <div>
                            <Tooltip placement="top" title={keyword ? undefined : 'Enter keyword first'}>
                                <div>
                                    <Input.TextArea
                                        disabled={keyword ? false : true}
                                        value={phrase}
                                        onChange={e => {
                                            setPhrase(e.target.value);
                                        }}
                                        autoSize={{ minRows: 1, maxRows: 4 }}
                                        placeholder="Phrase"
                                    />
                                </div>
                            </Tooltip>
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px' }}>
                                {suggestions.map((suggestion, index) => {
                                    return (
                                        <span
                                            style={{
                                                marginRight: '30px',
                                                cursor: 'pointer',
                                                color: 'rgba(0,0,0,.5)',
                                            }}
                                            onClick={() => {
                                                setPhrase(suggestion);
                                            }}
                                            key={index}
                                        >
                                            {suggestion}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <Space wrap style={{ alignItems: 'flex-start' }}>
                            <Tooltip
                                placement="top"
                                title={subjectSelectedIndex ? items[Number(subjectSelectedIndex)].label : undefined}
                            >
                                <Dropdown.Button
                                    //@ts-ignore
                                    menu={{
                                        ...menuProps,
                                        style: {
                                            maxHeight: '400px',
                                            overflow: 'scroll',
                                        },
                                    }}
                                    icon={<DownOutlined />}
                                    type="primary"
                                    htmlType="submit"
                                    loading={textLoading}
                                    disabled={phrase ? false : true}
                                >
                                    {getGenerateButtonString()}
                                </Dropdown.Button>
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>
            </Form>
            {generatedText?.text && (
                <div style={{ display: 'flex', margin: '20px 0 0 0' }}>
                    <Typography.Paragraph
                        type="secondary"
                        style={{
                            textAlign: 'justify',
                            marginRight: '12px',
                            position: 'relative',
                            flexGrow: 1,
                            border: '1px solid rgba(0,0,0,0.15)',
                            padding: '10px 30px 10px 10px',
                            borderRadius: '10px',
                            width: '100%',
                            height: '200px',
                            color: 'black',
                        }}
                        copyable={
                            generatedText.text == ''
                                ? false
                                : {
                                      text: generatedText.text,
                                      onCopy: () => {},
                                  }
                        }
                    >
                        <div style={{ paddingRight: '30px', height: '100%', overflow: 'scroll' }}>
                            {generatedText.text}
                        </div>
                    </Typography.Paragraph>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button onClick={addTextToPage} type="primary" className="addToBtn" value="content">
                            Add to Content
                        </Button>
                        <Button onClick={addTextToPage} type="primary" className="addToBtn" value="abstract">
                            Add to Abstract
                        </Button>
                        <Button onClick={addTextToPage} type="primary" className="addToBtn" value="conclusion">
                            Add to Conclusion
                        </Button>
                        <Button onClick={addTextToPage} type="primary" className="addToBtn" value="paper">
                            Add to Paper
                        </Button>
                    </div>
                </div>
            )}
            {generatedText?.text && (
                <Button
                    onClick={() => {
                        addSuggestions();
                        fetchText(generatedText?.text!, items[Number(subjectSelectedIndex)]?.label, true);
                    }}
                    type="primary"
                    loading={continueLoading}
                >
                    Continue Generate
                </Button>
            )}
        </Drawer>
    );
};

export default GenerateTextDrawer;
