import React, { useContext, useEffect, useState } from 'react';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Drawer, Typography, Divider, Button, Form, Input, Space, Radio, Select } from 'antd';
import Styled from 'styled-components';

import { GlobalContext } from '../../context/GlobalContextProvider';
import { generateReferenceString } from '../../helpers/generateReference';
import useReferences from '../../hooks/useReferences';

export const BookReferenceForm = ({ hideForm, category }: { hideForm: () => void; category?: string }) => {
    const [form] = Form.useForm();
    const [referenceString, setReferenceString] = useState<string>('');
    const { editReference, activeReference, setActiveReference, addReference } = useReferences();

    const conditionalFormReset = (change: any) => {
        if (change && change.type) {
            form.resetFields();
            form.setFieldsValue({ type: change.type });
        } else if (change && change.bookType) {
            const type = form.getFieldValue('type');
            form.resetFields();
            form.setFieldsValue({ type: type, bookType: change.bookType });
        } else if (change && change.chapterType) {
            const type = form.getFieldValue('type');
            form.resetFields();
            form.setFieldsValue({ type: type, chapterType: change.chapterType });
        }
    };

    const generatePreview = (change: any) => {
        conditionalFormReset(change);
        const referenceString = generateReferenceString(form.getFieldsValue());
        setReferenceString(referenceString);
    };
    form.getFieldsValue();

    useEffect(() => {
        if (activeReference) {
            form.setFieldsValue(activeReference);
            const referenceString = generateReferenceString(activeReference);
            setReferenceString(referenceString);
        }
    }, [activeReference]);

    const BookChapterForm = (
        <Form
            form={form}
            labelCol={{ span: 24 }}
            onFinish={reference => {
                if (activeReference) {
                    editReference(reference);
                    setActiveReference(null);
                    hideForm();
                    form.resetFields();
                } else {
                    delete reference['_id'];
                    addReference(reference);
                    hideForm();
                    form.resetFields();
                }
            }}
            onValuesChange={generatePreview}
        >
            <Form.Item name="_id" hidden>
                <Input />
            </Form.Item>
            <Form.Item label="Choose a type" name="type">
                <Radio.Group>
                    <Radio value="book">Book, e-book or audiobook</Radio>
                    <Radio value="chapter">Chapter in edited book</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item shouldUpdate labelCol={{ span: 24 }}>
                {() => {
                    const type = form.getFieldValue('type');
                    if (type === 'book')
                        return (
                            <Form.Item
                                label="What kind of book, e-book, or audiobook are you referencing?"
                                name="bookType"
                                shouldUpdate
                                labelCol={{ span: 24 }}
                            >
                                <Select>
                                    <Select.Option value="all-or-part">All or part of a book</Select.Option>
                                    <Select.Option value="foreign-or-translated">
                                        Foreign langauge or translated book
                                    </Select.Option>
                                    <Select.Option value="republished">Republished book</Select.Option>
                                    <Select.Option value="volume-in-series">Volume in series</Select.Option>
                                </Select>
                            </Form.Item>
                        );
                    else
                        return (
                            <Form.Item
                                label="What kind of chapter are you referencing?"
                                name="chapterType"
                                shouldUpdate
                                labelCol={{ span: 24 }}
                            >
                                <Select>
                                    <Select.Option value="all-or-part">All or part of a book chapter</Select.Option>
                                    <Select.Option value="foreign-or-translated">
                                        Foreign langauge or translated book
                                    </Select.Option>
                                    <Select.Option value="republished-from-book">
                                        Republished book, originally from a book
                                    </Select.Option>
                                    <Select.Option value="republished-from-journal">
                                        Republished book, originally from a journal
                                    </Select.Option>
                                    <Select.Option value="volume-in-series">Volume in series</Select.Option>
                                </Select>
                            </Form.Item>
                        );
                }}
            </Form.Item>

            <Divider />
            <Form.Item label="Contributors" labelCol={{ span: 24 }}>
                List contributors in the order they are listed in the work
                <Form.List name="contributors">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    key={field.key}
                                    label="Contributor Name"
                                    labelCol={{ span: 24 }}
                                    extra={
                                        <Button
                                            type="link"
                                            danger
                                            onClick={() => remove(field.name)}
                                            icon={<DeleteOutlined />}
                                        >
                                            Remove contributor
                                        </Button>
                                    }
                                    shouldUpdate
                                >
                                    {() => (
                                        <React.Fragment>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'role']}
                                                key={[field.key, 'role'].join('')}
                                                label="Role"
                                                labelCol={{ span: 6 }}
                                            >
                                                <Select>
                                                    <Select.Option value="author">Author</Select.Option>
                                                    <Select.Option value="editor">Editor</Select.Option>
                                                    <Select.Option value="group-author">
                                                        Group Author (Organization)
                                                    </Select.Option>
                                                    <Select.Option value="illustrator">Illustrator</Select.Option>
                                                    <Select.Option value="narrator">Narrator</Select.Option>
                                                    <Select.Option value="translator">Translator</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            {form.getFieldValue('contributors')[index] &&
                                                form.getFieldValue('contributors')[index].role !== 'group-author' && (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'first']}
                                                        key={[field.key, 'first'].join('')}
                                                        label="First"
                                                        labelCol={{ span: 6 }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                            {form.getFieldValue('contributors')[index] &&
                                                form.getFieldValue('contributors')[index].role !== 'group-author' && (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'middle']}
                                                        key={[field.key, 'middle'].join('')}
                                                        label="Middle"
                                                        labelCol={{ span: 6 }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                            {form.getFieldValue('contributors')[index] &&
                                                form.getFieldValue('contributors')[index].role !== 'group-author' && (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'last']}
                                                        key={[field.key, 'last'].join('')}
                                                        label="Last"
                                                        labelCol={{ span: 6 }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                            {form.getFieldValue('contributors')[index] &&
                                                form.getFieldValue('contributors')[index].role !== 'group-author' && (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'suffix']}
                                                        key={[field.key, 'suffix'].join('')}
                                                        label="Suffix"
                                                        labelCol={{ span: 6 }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                            {form.getFieldValue('contributors')[index] &&
                                                form.getFieldValue('contributors')[index].role === 'group-author' && (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'name']}
                                                        key={[field.key, 'name'].join('')}
                                                        label="Name"
                                                        labelCol={{ span: 6 }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                            {form.getFieldValue('contributors')[index] &&
                                                form.getFieldValue('contributors')[index].role === 'group-author' && (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'abbreviation']}
                                                        key={[field.key, 'abbreviation'].join('')}
                                                        label="Abbreviation"
                                                        labelCol={{ span: 6 }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                        </React.Fragment>
                                    )}
                                </Form.Item>
                            ))}
                            <Button onClick={() => add()} icon={<PlusOutlined />} type="link">
                                Add Contributor
                            </Button>
                        </>
                    )}
                </Form.List>
            </Form.Item>
            <Form.Item label="Book Publication Information">
                <Form.Item noStyle shouldUpdate>
                    {() =>
                        form.getFieldValue('bookType') === 'volume-in-series' ? (
                            <Form.Item
                                name="volumeTitle"
                                labelCol={{ span: 24 }}
                                label="Volume Title"
                                extra="Type the title of the single volume (book) from the multi-volume series. Don't include a volume number."
                            >
                                <Input />
                            </Form.Item>
                        ) : (
                            <></>
                        )
                    }
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                    {() =>
                        form.getFieldValue('bookType') === 'volume-in-series' ? (
                            <Form.Item
                                name="seriesTitle"
                                labelCol={{ span: 24 }}
                                label="Series Title"
                                extra="Type the title of the series if it is included on each of the separate volumes. If you're unsure, omit the series title."
                            >
                                <Input />
                            </Form.Item>
                        ) : (
                            <></>
                        )
                    }
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                    {() =>
                        !form.getFieldValue('bookType') && form.getFieldValue('chapterType') !== 'volume-in-series' ? (
                            <Form.Item
                                name="chapterTitle"
                                labelCol={{ span: 24 }}
                                label="Chapter Title"
                                extra="Only capitalize the first word of the title &amp; subtitle, and any proper nouns. If there isn't a title, type a description in brackets."
                            >
                                <Input />
                            </Form.Item>
                        ) : (
                            <></>
                        )
                    }
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                    {() =>
                        !form.getFieldValue('bookType') &&
                        form.getFieldValue('chapterType') === 'foreign-or-translated' ? (
                            <Form.Item
                                name="translatedChapterTitle"
                                labelCol={{ span: 24 }}
                                label="Translated Chapter Title"
                                extra="If the original book title is provide in another language, type the translated title."
                            >
                                <Input />
                            </Form.Item>
                        ) : (
                            <></>
                        )
                    }
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                    {() =>
                        form.getFieldValue('bookType') !== 'volume-in-series' ? (
                            <Form.Item
                                name="bookTitle"
                                labelCol={{ span: 24 }}
                                label="Book Title"
                                extra="Only capitalize the first word of the title &amp; subtitle, and any proper nouns. If there isn't a title, add a description inside brackets."
                            >
                                <Input />
                            </Form.Item>
                        ) : (
                            <></>
                        )
                    }
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                    {() =>
                        form.getFieldValue('bookType') === 'foreign-or-translated' ||
                        form.getFieldValue('bookType') === 'republished' ? (
                            <Form.Item
                                name="translatedBookTitle"
                                labelCol={{ span: 24 }}
                                label="Translated Book Title"
                                extra="If the original book title is provide in another language, type the translated title."
                            >
                                <Input />
                            </Form.Item>
                        ) : (
                            <></>
                        )
                    }
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                    {() =>
                        !form.getFieldValue('bookType') && form.getFieldValue('chapterType') !== 'volume-in-series' ? (
                            <Form.Item
                                name="chapterPageNumbers"
                                labelCol={{ span: 24 }}
                                label="Chapter Page Numbers"
                                extra="Give the page numbers that include the reference text (ex. 45–78). Discontinuous pages are separated with comma and space. Ex. 45–78, 93."
                            >
                                <Input />
                            </Form.Item>
                        ) : (
                            <></>
                        )
                    }
                </Form.Item>
                <Form.Item
                    name="publicationYear"
                    labelCol={{ span: 24 }}
                    label="Publication Year"
                    extra="List the year the work was published."
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item
                    name="edition"
                    labelCol={{ span: 24 }}
                    label="Edition"
                    extra="For numbered editions, type the ordinal number (1st, 2nd, etc.). For named editions, type the name (e.g., 2nd ed., rev.)."
                >
                    <Input />
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                    {() =>
                        form.getFieldValue('bookType') === 'volume-in-series' ? (
                            <Form.Item
                                name="volume"
                                labelCol={{ span: 24 }}
                                label="Volume Number"
                                extra="Use numerals for the volume number."
                            >
                                <Input type="number" />
                            </Form.Item>
                        ) : (
                            <></>
                        )
                    }
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                    {() =>
                        form.getFieldValue('bookType') && !form.getFieldValue('chapterType') ? (
                            <Form.Item
                                name="format"
                                labelCol={{ span: 24 }}
                                label="Format"
                                extra="Only include a format if the content of the e-book or audiobook is different from the original book."
                            >
                                <Input />
                            </Form.Item>
                        ) : (
                            <></>
                        )
                    }
                </Form.Item>
            </Form.Item>
            <Form.Item label="Publisher's Name">
                Don&apos;t include business titles (e.g., Inc., Co., Ltd., etc.).
                <Form.List name="publishers">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(field => (
                                <Form.Item
                                    extra={
                                        <Button
                                            type="link"
                                            danger
                                            onClick={() => remove(field.name)}
                                            icon={<DeleteOutlined />}
                                        >
                                            Remove publisher
                                        </Button>
                                    }
                                    key={field.key}
                                >
                                    <Form.Item {...field} key={`${field.key}-publisher`}>
                                        <Input />
                                    </Form.Item>
                                </Form.Item>
                            ))}
                            <Button onClick={() => add()} icon={<PlusOutlined />} type="link">
                                Add Publisher
                            </Button>
                        </>
                    )}
                </Form.List>
            </Form.Item>
            <Form.Item label="DOI / URL" extra="Only include the DOI number (10.###...)." name="urlDoi">
                <Input />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
                {() =>
                    form.getFieldValue('bookType') === 'foreign-or-translated' ||
                    form.getFieldValue('bookType') === 'republished' ||
                    form.getFieldValue('chapterType') === 'republished-from-journal' ? (
                        <Form.Item label="Original Publication Information" labelCol={{ span: 24 }}></Form.Item>
                    ) : (
                        <></>
                    )
                }
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
                {() =>
                    form.getFieldValue('chapterType') === 'republished-from-journal' ? (
                        <>
                            <Form.Item
                                name="originalArticleTitle"
                                labelCol={{ span: 24 }}
                                label="Original Article Title"
                                extra="Type the full title of the article. Only capitalize the first word of the title &amp; subtitle, and any proper nouns."
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="originalJournalTitle"
                                labelCol={{ span: 24 }}
                                label="Original Journal Title"
                                extra="Type the name of the journal exactly as it appears in the work."
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="originalVolumeNumber"
                                labelCol={{ span: 24 }}
                                label="Original Volume Number"
                                extra="Use numbers for the volume number."
                            >
                                <Input type="number" />
                            </Form.Item>
                            <Form.Item
                                name="originalIssueNumber"
                                labelCol={{ span: 24 }}
                                label="Original Issue Number"
                                extra="Use numbers for the issue number."
                            >
                                <Input type="number" />
                            </Form.Item>
                            <Form.Item
                                name="originalPageNumbers"
                                labelCol={{ span: 24 }}
                                label="Original Page Numbers"
                                extra="Give the page numbers that include the reference text (ex. 45–78). Discontinuous pages are separated with comma and space. Ex. 45–78, 93."
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="originalDoiUrl"
                                labelCol={{ span: 24 }}
                                label="Original DOI / URL"
                                extra="Only include the DOI number (10.###...)."
                            >
                                <Input />
                            </Form.Item>
                        </>
                    ) : (
                        <></>
                    )
                }
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
                {() =>
                    form.getFieldValue('bookType') === 'republished' ? (
                        <Form.Item
                            name="originalBookTitle"
                            labelCol={{ span: 24 }}
                            label="Original Book Title"
                            extra="Type the full title of the work. Only capitalize the first word of the title &amp; subtitle, and any proper nouns."
                        >
                            <Input />
                        </Form.Item>
                    ) : (
                        <></>
                    )
                }
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
                {() =>
                    form.getFieldValue('bookType') === 'foreign-or-translated' ||
                    form.getFieldValue('bookType') === 'republished' ||
                    form.getFieldValue('chapterType') === 'foreign-or-translated' ||
                    form.getFieldValue('chapterType') === 'republished-from-journal' ? (
                        <Form.Item
                            label="Original Publication Year"
                            labelCol={{ span: 24 }}
                            extra="List the year the original work was published."
                            name="originalPublicationYear"
                        >
                            <Input type="number" />
                        </Form.Item>
                    ) : (
                        <></>
                    )
                }
            </Form.Item>
            <Form.Item label="Live Preview" style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
                <div dangerouslySetInnerHTML={{ __html: referenceString }} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" block>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );

    const WebpageForm = (
        <Form
            form={form}
            labelCol={{ span: 24 }}
            onFinish={reference => {
                if (activeReference) {
                    editReference(reference);
                    setActiveReference(null);
                    hideForm();
                    form.resetFields();
                } else {
                    delete reference['_id'];
                    addReference(reference);
                    hideForm();
                    form.resetFields();
                }
            }}
            onValuesChange={generatePreview}
        >
            <Form.Item name="_id" hidden>
                <Input />
            </Form.Item>
            <Form.Item name="type" hidden initialValue="webpage">
                <Input type="webpage" />
            </Form.Item>
            <Form.Item label="Contributors" labelCol={{ span: 24 }}>
                List contributors in the order they are listed in the work
                <Form.List name="contributors">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    key={field.key}
                                    label="Contributor Name"
                                    labelCol={{ span: 24 }}
                                    extra={
                                        <Button
                                            type="link"
                                            danger
                                            onClick={() => remove(field.name)}
                                            icon={<DeleteOutlined />}
                                        >
                                            Remove contributor
                                        </Button>
                                    }
                                    shouldUpdate
                                >
                                    {() => (
                                        <React.Fragment>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'role']}
                                                key={[field.key, 'role'].join('')}
                                                label="Role"
                                                labelCol={{ span: 6 }}
                                            >
                                                <Select>
                                                    <Select.Option value="author">Author</Select.Option>
                                                    <Select.Option value="editor">Editor</Select.Option>
                                                    <Select.Option value="group-author">
                                                        Group Author (Organization)
                                                    </Select.Option>
                                                    <Select.Option value="translator">Translator</Select.Option>
                                                    <Select.Option value="username">
                                                        Username (screen name)
                                                    </Select.Option>
                                                </Select>
                                            </Form.Item>
                                            {form.getFieldValue('contributors')[index] &&
                                                form.getFieldValue('contributors')[index].role !== 'group-author' && (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'first']}
                                                        key={[field.key, 'first'].join('')}
                                                        label="First"
                                                        labelCol={{ span: 6 }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                            {form.getFieldValue('contributors')[index] &&
                                                form.getFieldValue('contributors')[index].role !== 'group-author' && (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'middle']}
                                                        key={[field.key, 'middle'].join('')}
                                                        label="Middle"
                                                        labelCol={{ span: 6 }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                            {form.getFieldValue('contributors')[index] &&
                                                form.getFieldValue('contributors')[index].role !== 'group-author' && (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'last']}
                                                        key={[field.key, 'last'].join('')}
                                                        label="Last"
                                                        labelCol={{ span: 6 }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                            {form.getFieldValue('contributors')[index] &&
                                                form.getFieldValue('contributors')[index].role !== 'group-author' && (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'suffix']}
                                                        key={[field.key, 'suffix'].join('')}
                                                        label="Suffix"
                                                        labelCol={{ span: 6 }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                            {form.getFieldValue('contributors')[index] &&
                                                form.getFieldValue('contributors')[index].role === 'group-author' && (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'name']}
                                                        key={[field.key, 'name'].join('')}
                                                        label="Name"
                                                        labelCol={{ span: 6 }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                            {form.getFieldValue('contributors')[index] &&
                                                form.getFieldValue('contributors')[index].role === 'group-author' && (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'abbreviation']}
                                                        key={[field.key, 'abbreviation'].join('')}
                                                        label="Abbreviation"
                                                        labelCol={{ span: 6 }}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                        </React.Fragment>
                                    )}
                                </Form.Item>
                            ))}
                            <Button onClick={() => add()} icon={<PlusOutlined />} type="link">
                                Add Contributor
                            </Button>
                        </>
                    )}
                </Form.List>
            </Form.Item>
            <Form.Item label="Book Publication Information">
                <Form.Item
                    name="pageTitle"
                    labelCol={{ span: 24 }}
                    label="Page Title"
                    extra="Only capitalize the first word of the title &amp; subtitle, and any proper nouns. If there isn't a title, add a description inside brackets."
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="websiteName"
                    labelCol={{ span: 24 }}
                    label="Website Name"
                    extra="Type the name of the website or organization exactly as it appears in the publication."
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="publicationDate"
                    labelCol={{ span: 24 }}
                    label="Publication Date"
                    extra="Only capitalize the first word of the title &amp; subtitle, and any proper nouns. If there isn't a title, type a description in brackets."
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="format"
                    labelCol={{ span: 24 }}
                    label="Format"
                    extra="Only include a format if the webpage doesn't fit into another category better (a PDF of a journal article) or isn't a standard webpage."
                >
                    <Input />
                </Form.Item>
            </Form.Item>
            <Form.Item label="Digital Information">
                <Form.Item name="urlDoi" labelCol={{ span: 24 }} label="URL (Web address)">
                    <Input />
                </Form.Item>
                <Form.Item
                    name="retrievalDate"
                    labelCol={{ span: 24 }}
                    label="Retrieval Date"
                    extra="If the work may change in the future, enter the date you accessed the work for your research."
                >
                    <Input />
                </Form.Item>
            </Form.Item>
            <Form.Item label="DOI / URL" extra="Only include the DOI number (10.###...)." name="urlDoi">
                <Input />
            </Form.Item>
            <Form.Item label="Live Preview" style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
                <div dangerouslySetInnerHTML={{ __html: referenceString }} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" block>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );

    return category === 'book' ? BookChapterForm : category === 'webpage' ? WebpageForm : <></>;
};

export default BookReferenceForm;
