import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { Button, Card, Divider, Drawer, Empty, List, Space, Typography } from 'antd';
// import { convert } from 'html-to-text'
import h2p from 'html2plaintext';
import { parse, HTMLElement, Node as ParserNode, TextNode } from 'node-html-parser';
import wordCount from 'wordcount';

import { GlobalContext } from '../context/GlobalContextProvider';
import { usePaper } from '../hooks';
import { countPages, getRepeatingSubstrings, handleError, handleSuccess, random_bg_color } from '../utility';

export const AutoCheckDrawer = React.memo(() => {
    const context = useContext(GlobalContext);
    const closeModal = () => {
        context.setShowReferenceCheckDrawer(false);
        context.setPerformedSystemCheck(true);
    };
    const [pages, setPages] = useState(0);
    const { getCleanHtml } = usePaper();

    const references = useMemo(() => context.activePaper?.references, [context.showReferenceCheckDrawer]);
    const html = useMemo(() => {
        if (context.showReferenceCheckDrawer) return context.html;
        else return '';
    }, [context.showReferenceCheckDrawer]);

    useEffect(() => {
        context.setPerformedSystemCheck(true);
    }, []);

    const getReferenceStatus = (reference: Papers.Reference): 'unsure' | 'used' | 'unused' => {
        const citation = reference.rawCitation;
        if (!citation) return 'unsure';

        if (html?.includes(citation) || html.replace(/&amp;/gi, '&#38;')?.includes(citation)) return 'used';
        else return 'unused';
    };

    const getStatusIcon = (status: 'unsure' | 'used' | 'unused') => {
        if (status === 'used') return <CheckCircleFilled style={{ color: '#27ae60', fontSize: '32px' }} />;
        else if (status === 'unsure') return <CheckCircleFilled style={{ color: '#f39c12', fontSize: '32px' }} />;
        else return <CloseCircleFilled style={{ color: '#e74c3c', fontSize: '32px' }} />;
    };

    const renderReference = (reference: Papers.Reference) => {
        const referenceStatus = getReferenceStatus(reference);
        return (
            <List.Item extra={getStatusIcon(referenceStatus)}>
                <div dangerouslySetInnerHTML={{ __html: reference.rawReference! }} />
            </List.Item>
        );
    };

    const calculateTotalPages = async () => {
        try {
            const question = context.titlePage.question;
            if (!question) throw new Error('Question has not been set');
            const courseNumberAndName = context.titlePage.courseNumberAndName;
            if (!courseNumberAndName) throw new Error('Course name and number has not been set');
            const html = context.html;
            const cleanHtml = getCleanHtml(html);
            const pages = await countPages({
                htmlString: cleanHtml,
                runningHeadTitle: context.titlePage.runningHead || '',
            });
            setPages(pages);
        } catch (err) {
            handleError(err);
        }
    };

    const removeInvalidHighlights = () => {
        context.setHtml(html => {
            const root = parse(html);
            const spans = root.querySelectorAll('span');
            spans.forEach(span => {
                const className = span.classNames;
                if (className === 'highlight_invalid_between' || className === 'highlight_invalid_start') {
                    span.replaceWith(span.innerText);
                }
            });
            return root.toString();
        });
    };

    const highlightInvalidWords = () => {
        try {
            context.setHtml(html => {
                const root = parse(html);
                const spans = root.querySelectorAll('span');

                // Remove all highlights to prevent duplicate highlights
                spans.forEach(span => {
                    const className = span.classNames;
                    if (className === 'highlight_invalid_between' || className === 'highlight_invalid_start') {
                        span.replaceWith(span.innerText);
                    }
                });

                // Remove reference page
                const referencePageNode = root.querySelector('#referencesPage');
                const referencePageHtml = referencePageNode?.toString();
                const dummyReferencePageNode = parse('<div id="dummyReferencePage"></div>');
                const mainNode = root.querySelector('div');
                if (referencePageNode) {
                    mainNode?.exchangeChild(referencePageNode, dummyReferencePageNode);
                    referencePageNode?.remove();
                }

                // Highlight invalid words
                let newHtml = root.toString();
                ['i', 'mine', 'we', 'you', 'your', 'my', 'our', 'he', 'she'].forEach(word => {
                    const regExCaseSensitive = new RegExp(`\\b${word}\\b`, 'gi');
                    newHtml = [...new Set(newHtml.match(regExCaseSensitive))].reduce((previous, current) => {
                        const regExCaseInsensitive = new RegExp(`\\b${current}\\b`, 'g');
                        return previous.replace(
                            regExCaseInsensitive,
                            `<span class="highlight_invalid_between">${current}</span>`,
                        );
                    }, newHtml);
                });

                // Highlight invalid paragraph start
                ['It', 'This', 'Or', 'And', 'But', 'So', 'Also', 'Because'].forEach(word => {
                    const regEx = new RegExp(`\<p>${word}\\b`, 'g');
                    newHtml = newHtml.replace(regEx, `<p><span class="highlight_invalid_start">${word}</span>`);
                });

                // Add back reference node
                if (referencePageNode) {
                    const newHtmlRoot = parse(newHtml);
                    const mainNode = newHtmlRoot.querySelector('div');
                    const dummyReferencePageNode = newHtmlRoot.querySelector('#dummyReferencePage');
                    const originalReferencePageNode = parse(referencePageHtml!);
                    mainNode?.exchangeChild(dummyReferencePageNode!, originalReferencePageNode);
                    return mainNode?.toString()!;
                }

                return newHtml;
            });
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        if (context.showReferenceCheckDrawer === true) calculateTotalPages();
        else {
            setPages(0);
        }
    }, [context.showReferenceCheckDrawer]);

    const totalWordCount = useMemo(() => wordCount(h2p(html)), [context.showReferenceCheckDrawer]);
    const abstractWordCount = useCallback(() => {
        const root = parse(html);
        const abstractPage = root.querySelector('#abstractPage');
        return wordCount(h2p(abstractPage?.toString()));
    }, [context.showReferenceCheckDrawer]);
    const conclusionWordCount = useCallback(() => {
        const root = parse(html);
        const abstractPage = root.querySelector('#conclusionPage');
        return wordCount(h2p(abstractPage?.toString()));
    }, [context.showReferenceCheckDrawer]);

    const getTextNodesIn = (elem: HTMLElement | ParserNode, opt_fnFilter?: any) => {
        let textNodes: ParserNode[] = [];
        if (elem) {
            for (let nodes = elem.childNodes, i = nodes.length; i--; ) {
                const node = nodes[i],
                    nodeType = node.nodeType;
                if (nodeType == 3) {
                    if (!opt_fnFilter || opt_fnFilter(node, elem)) {
                        textNodes.push(node);
                    }
                } else if (nodeType == 1) {
                    textNodes = textNodes.concat(getTextNodesIn(node, opt_fnFilter));
                }
            }
        }
        return textNodes;
    };

    const highlightRepeatingSubstring = () => {
        context.setHtml(html => {
            const root = parse(html);
            const spans = root.querySelectorAll('span');

            // Remove all highlights to prevent duplicate highlights
            spans.forEach(span => {
                const className = span.classNames;
                if (
                    className === 'highlight_invalid_between' ||
                    className === 'highlight_invalid_start' ||
                    className === 'repeated-paraphrase'
                ) {
                    span.replaceWith(span.innerText);
                }
            });
            const abstractPage = root.querySelector('#abstractPage');
            const abstractPageContents = h2p(abstractPage);
            let newHtml = root.toString();
            const repeatingParaphrases = getRepeatingSubstrings(abstractPageContents, []);

            const abstractPageNode = root.querySelector('#abstractPage');
            const abstractPageHtml = abstractPageNode?.toString();
            const dummyAbstractPageNode = parse('<div id="dummyAbstractPage"></div>');
            const mainNode = root.querySelector('div');
            if (abstractPageNode) {
                mainNode?.exchangeChild(abstractPageNode, dummyAbstractPageNode);
                abstractPageNode?.remove();
            }

            for (const repeatingParaphrase of repeatingParaphrases) {
                newHtml = newHtml.replaceAll(
                    repeatingParaphrase.trim(),
                    `<span class="highlight_invalid_between" style="background-color:${random_bg_color()};margin-right:3px;">${repeatingParaphrase}</span>`,
                );
            }

            if (abstractPageNode) {
                const newHtmlRoot = parse(newHtml);
                const mainNode = newHtmlRoot.querySelector('div');
                const dummyAbstractPageNode = parse('<div id="dummyAbstractPage"></div>');
                const originalAbstractPageNode = parse(abstractPageHtml!);
                mainNode?.exchangeChild(dummyAbstractPageNode!, originalAbstractPageNode);
            }
            const conclusionPage = root.querySelector('#conclusionPage');
            const conclusionPageContents = h2p(conclusionPage);
            const repeatingParaphrasesConclusion = getRepeatingSubstrings(conclusionPageContents, []);
            const conclusionPageNode = root.querySelector('#conclusionPage');
            const conclusionPageHtml = conclusionPageNode?.toString();
            const dummyConclusionPageNode = parse('<div id="dummyConclusionPage"></div>');
            const mainNodeConclusion = root.querySelector('div');
            if (conclusionPageNode) {
                mainNodeConclusion?.exchangeChild(conclusionPageNode, dummyConclusionPageNode);
                conclusionPageNode?.remove();
            }

            for (const repeatingParaphrase of repeatingParaphrasesConclusion) {
                newHtml = newHtml.replaceAll(
                    repeatingParaphrase.replaceAll('.', '').trim(),
                    `<span class="highlight_invalid_between" style="background-color:${random_bg_color()};margin-right:3px;">${repeatingParaphrase}</span>`,
                );
            }

            if (conclusionPageNode) {
                const newHtmlRoot = parse(newHtml);
                const mainNodeConclusion = newHtmlRoot.querySelector('div');
                const dummyConclusionPageNode = parse('<div id="dummyConclusionPage"></div>');
                const originalConclusionPageNode = parse(conclusionPageHtml!);
                mainNodeConclusion?.exchangeChild(dummyConclusionPageNode!, originalConclusionPageNode);
            }

            const contentPage = root.querySelector('#contentPage');
            const contentPageContents = h2p(contentPage);
            const repeatingParaphrasesContent = getRepeatingSubstrings(contentPageContents, []);
            const contentPageNode = root.querySelector('#contentPage');
            const contentPageHtml = contentPageNode?.toString();
            const dummyContentPageNode = parse('<div id="dummyContentPage"></div>');
            const mainNodeContent = root.querySelector('div');
            if (contentPageNode) {
                mainNodeContent?.exchangeChild(contentPageNode, dummyContentPageNode);
                contentPageNode?.remove();
            }

            for (const repeatingParaphrase of repeatingParaphrasesContent) {
                newHtml = newHtml.replaceAll(
                    repeatingParaphrase.replaceAll('.', '').trim(),
                    `<span class="highlight_invalid_between" style="background-color:${random_bg_color()};margin-right:3px;">${repeatingParaphrase}</span>`,
                );
            }

            if (contentPageNode) {
                const newHtmlRoot = parse(newHtml);
                const mainNodeContent = newHtmlRoot.querySelector('div');
                const dummyContentPageNode = parse('<div id="dummyContentPage"></div>');
                const originalContentPageNode = parse(contentPageHtml!);
                mainNodeContent?.exchangeChild(dummyContentPageNode!, originalContentPageNode);
                return mainNodeContent?.toString()!;
            }
            return newHtml;
        });
    };

    const removeInvalidTokens = () => {
        try {
            context.setHtml(html => {
                let newHtml = JSON.parse(JSON.stringify(html)) as string;

                newHtml = newHtml.replaceAll(/  /gi, ' ');
                newHtml = newHtml.replaceAll(/&nbsp; /gi, ' ');
                newHtml = newHtml.replaceAll(/ &nbsp; /gi, ' ');
                newHtml = newHtml.replaceAll(/ &nbsp;/gi, ' ');

                newHtml = newHtml.replaceAll(/\?/gi, '');

                newHtml = newHtml.replaceAll(/<p>&nbsp;<\/p>/gi, '');
                newHtml = newHtml.replaceAll(/<p><\/p>/gi, '');
                newHtml = newHtml.replaceAll(/<p> <\/p>/gi, '');

                const root = parse(newHtml);
                const texts = getTextNodesIn(root);
                texts.forEach(text => {
                    text.textContent = text.textContent.replaceAll(/"/gi, '');
                });
                newHtml = root.toString();

                // Bug fix: Docx file not opening after adding a table and
                // running remove invalid token together
                newHtml = newHtml.replaceAll(/#xA0/gi, 'nbsp');

                return newHtml;
            });
            handleSuccess('Invalid tokens have been removed');
        } catch (err) {
            handleError(err);
        }
    };

    const { systemCheckStack: stack } = context;

    function coninueAnyway() {
        context.setShowReferenceCheckDrawer(false);
        context.setPerformedSystemCheck(true);
        switch (stack) {
            case 'export':
                context.setShowExportModal(true);
                break;
            case 'share':
                context.setSharePaper(context.activePaper);
            default:
                break;
        }
    }

    return (
        <Drawer
            visible={context.showReferenceCheckDrawer}
            onClose={closeModal}
            title="System Checks"
            destroyOnClose={true}
            bodyStyle={{ padding: '8px' }}
            mask={false}
        >
            {context.showReferenceCheckDrawer && (
                <Space direction="vertical" style={{ width: '100%' }}>
                    {context.systemCheckStack ? (
                        <div style={{ color: 'red', padding: '8px' }}>
                            Please review system checks before {stack === 'export' ? 'exporting' : 'sharing'} your
                            paper.
                        </div>
                    ) : null}
                    <Card title={<Typography.Title level={4}>Word Count</Typography.Title>}>
                        <Space style={{ width: '100%', textAlign: 'center', justifyContent: 'space-between' }}>
                            <Space direction="vertical" style={{ flexGrow: 1 }}>
                                <Typography.Title
                                    level={3}
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        color: totalWordCount < 150 || totalWordCount > 250 ? '#e74c3c' : '#27ae60',
                                    }}
                                >
                                    {totalWordCount}
                                </Typography.Title>
                                <Typography.Text type="secondary">Total</Typography.Text>
                            </Space>
                            <Space direction="vertical" style={{ flexGrow: 1 }}>
                                <Typography.Title
                                    level={3}
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        color:
                                            abstractWordCount() < 150 || abstractWordCount() > 250
                                                ? '#e74c3c'
                                                : '#27ae60',
                                    }}
                                >
                                    {abstractWordCount()}
                                </Typography.Title>
                                <Typography.Text type="secondary">Abstract</Typography.Text>
                            </Space>
                            <Space direction="vertical" style={{ flexGrow: 1 }}>
                                <Typography.Title
                                    level={3}
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        color:
                                            conclusionWordCount() < 150 || conclusionWordCount() > 250
                                                ? '#e74c3c'
                                                : '#27ae60',
                                    }}
                                >
                                    {conclusionWordCount()}
                                </Typography.Title>
                                <Typography.Text type="secondary">Conclusion</Typography.Text>
                            </Space>
                        </Space>
                    </Card>

                    <Card title={<Typography.Title level={4}>Total Page Count</Typography.Title>}>
                        <Typography.Title
                            level={3}
                            style={{
                                marginTop: 0,
                                marginBottom: 0,
                            }}
                            type={pages <= 0 ? 'secondary' : undefined}
                        >
                            {pages > 0 ? pages : 'calculating...'}
                        </Typography.Title>
                    </Card>

                    <Card title={<Typography.Title level={4}>Invalid Tokens</Typography.Title>}>
                        <ul>
                            <li>Removes double spaces</li>
                            <li>Removes question marks</li>
                            <li>Removes double quotes</li>
                            <li>Removes double line breaks</li>
                        </ul>
                        <Button type="primary" onClick={removeInvalidTokens} block>
                            Remove Invalid Tokens
                        </Button>
                    </Card>

                    <Card title={<Typography.Title level={4}>Repeated Paraphrases</Typography.Title>}>
                        <Button type="primary" block onClick={highlightRepeatingSubstring}>
                            Highlight Repeated Paraphrases
                        </Button>
                    </Card>

                    <Card title={<Typography.Title level={4}>Invalid Words</Typography.Title>}>
                        <Space style={{ textAlign: 'center', width: '100%', justifyContent: 'space-evenly' }}>
                            <Space direction="vertical" style={{ flexGrow: 1 }}>
                                <InfoCircleFilled style={{ color: 'rgba(243, 156, 18, 0.5)', fontSize: '32px' }} />{' '}
                                Invalid Starting
                            </Space>
                            <Space direction="vertical" style={{ flexGrow: 1 }}>
                                <InfoCircleFilled style={{ color: 'rgba(211, 84, 0,1.0)', fontSize: '32px' }} /> Invalid
                                Word
                            </Space>
                        </Space>
                        <Divider />
                        <Button type="primary" block onClick={highlightInvalidWords}>
                            Highlight Invalid Words
                        </Button>
                        <br /> <br />
                        <Button type="primary" block onClick={removeInvalidHighlights}>
                            Remove Highlights
                        </Button>
                    </Card>

                    <Card title={<Typography.Title level={4}>Reference Citation Check</Typography.Title>}>
                        <Space style={{ textAlign: 'center', width: '100%', justifyContent: 'space-evenly' }}>
                            <Space direction="vertical" style={{ flexGrow: 1 }}>
                                <CheckCircleFilled style={{ color: '#27ae60', fontSize: '32px' }} /> Cited
                            </Space>
                            <Space direction="vertical" style={{ flexGrow: 1 }}>
                                <CloseCircleFilled style={{ color: '#e74c3c', fontSize: '32px' }} /> Uncited
                            </Space>
                        </Space>

                        <Divider />

                        <List dataSource={references || []} renderItem={renderReference} />
                    </Card>
                    {context.systemCheckStack ? (
                        <Button type="default" block onClick={coninueAnyway}>
                            Continue Anyway
                        </Button>
                    ) : null}
                </Space>
            )}
        </Drawer>
    );
});
