import React, { useContext, useState } from 'react';

import { Button, Divider, Modal, Space, Spin, Input } from 'antd';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';

import { GlobalContext } from '../context/GlobalContextProvider';
import { useAuth, usePaper } from '../hooks';
import { handleError, htmlToPdf, emailPdf, emailDocx, handleSuccess } from '../utility';

export const ExportModal = ({ visible }: { visible: boolean }) => {
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);

    const { logout, loggedInUser, isAdmin, localLoginCheck } = useAuth();
    const { getCleanHtml, getChildren, getDocument } = usePaper();

    const [email, setEmail] = useState(loggedInUser?.emailId || '');

    const closeModal = () => {
        context.setShowExportModal(false);
    };

    const downloadDocx = async () => {
        try {
            setLoading(true);
            const question = context.titlePage.question;
            if (!question) throw new Error('Question has not been set');
            const courseNumberAndName = context.titlePage.courseNumberAndName;
            if (!courseNumberAndName) throw new Error('Course name and number has not been set');
            const html = context.html;
            const cleanHtml = getCleanHtml(html);

            const document = getDocument(cleanHtml);

            Packer.toBlob(document).then(blob => {
                saveAs(blob, context.titlePage.title + '.docx');
            });
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const downloadPdf = async () => {
        try {
            setLoading(true);
            const question = context.titlePage.question;
            if (!question) throw new Error('Question has not been set');
            const courseNumberAndName = context.titlePage.courseNumberAndName;
            if (!courseNumberAndName) throw new Error('Course name and number has not been set');
            const html = context.html;
            const cleanHtml = getCleanHtml(html);
            const res = await htmlToPdf({
                htmlString: cleanHtml,
                runningHeadTitle: context.titlePage.runningHead || '',
            });
            saveAs(res);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const sendEmailDocx = async () => {
        try {
            setLoading(true);
            const question = context.titlePage.question;
            if (!question) throw new Error('Question has not been set');
            const courseNumberAndName = context.titlePage.courseNumberAndName;
            if (!courseNumberAndName) throw new Error('Course name and number has not been set');
            const html = context.html;
            const cleanHtml = getCleanHtml(html);
            const document = getDocument(cleanHtml);

            const blob = await Packer.toBlob(document);
            const res = await emailDocx({
                docxBlob: blob,
                emailId: email,
                subject: context.titlePage.title || '',
            });
            if (res) {
                handleSuccess('Email sent successfully');
            } else {
                handleError('failed to send the document on email');
            }
        } catch (err) {
            handleError(err);
        } finally {
            closeModal();
            setLoading(false);
        }
    };

    const sendEmailPdf = async () => {
        try {
            setLoading(true);
            const question = context.titlePage.question;
            if (!question) throw new Error('Question has not been set');
            const courseNumberAndName = context.titlePage.courseNumberAndName;
            if (!courseNumberAndName) throw new Error('Course name and number has not been set');
            const html = context.html;
            const cleanHtml = getCleanHtml(html);
            const res = await emailPdf({
                emailId: email,
                htmlString: cleanHtml,
                subject: context.titlePage.title?.toUpperCase() || '',
                runningHeadTitle: context.titlePage.runningHead || '',
            });
            if (res) {
                handleSuccess('Email sent successfully');
            } else {
                handleError('failed to send the document on email');
            }
        } catch (err) {
            handleError(err);
        } finally {
            closeModal();
            setLoading(false);
        }
    };

    return (
        <>
            <Modal
                visible={visible}
                onCancel={closeModal}
                title="Export Paper"
                destroyOnClose={true}
                footer={null}
                className="export"
                style={{ fontFamily: 'Times New Roman !important' }}
                bodyStyle={{ fontFamily: 'Times New Roman !important' }}
            >
                <Spin spinning={loading}>
                    <h4>Send Paper via Email</h4>
                    <Input
                        placeholder="Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        style={{ width: '100%', marginBottom: '10px' }}
                    />
                    <Space>
                        <Button type="primary" onClick={() => sendEmailDocx()}>
                            Send Word File
                        </Button>
                        <Button type="primary" onClick={() => sendEmailPdf()}>
                            Send PDF File
                        </Button>
                    </Space>
                    <Divider />
                    <h4>Download Paper</h4>
                    <Space>
                        <Button type="primary" block onClick={downloadDocx}>
                            Download Word File
                        </Button>
                        <Button type="primary" block onClick={downloadPdf}>
                            Download as PDF
                        </Button>
                    </Space>
                </Spin>
            </Modal>
        </>
    );
};

export default ExportModal;
