import React from 'react';

export const ContentPage = (values: AutoAPA.ContentPage) => {
    return (
        <>
            <div
                id="contentPage"
                className="contentPage"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    outline: 'none',
                }}
            >
                <h1 className="mceNonEditable">{values.title}</h1>

                <div
                    className="mceEditable"
                    style={{ flexGrow: values.integrateReferences ? 0 : 1, outline: 'none', lineHeight: 2 }}
                ></div>

                {values.integrateReferences && (
                    <div
                        id="referencePage"
                        className="referencePage mceNonEditable"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            outline: 'none',
                            lineHeight: 2,
                        }}
                    >
                        <p
                            className="mceNonEditable text-center no-indent"
                            style={{ textAlign: 'center', lineHeight: 2 }}
                        >
                            <strong>References</strong>
                        </p>

                        <div
                            className="mceNonEditable"
                            style={{ flexGrow: 1, outline: 'none' }}
                            id="referenceList"
                        ></div>
                    </div>
                )}
            </div>
            <div
                className="page-break mceNonEditable"
                style={{ overflow: 'hidden', height: 0, pageBreakAfter: 'always' }}
            />
        </>
    );
};

export default ContentPage;
