import React, { useContext, useEffect, useRef, useState } from 'react';

import { Grammarly, GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import ReactDOMServer from 'react-dom/server';
import { Editor as TinyMCEEditor } from 'tinymce';

import AbstractPage from './AbstractPage';
import ConclusionPage from './ConclusionPage';
import ContentPage from './ContentPage';
import Page from './Page';
import ReferencesPage from './ReferencesPage';
import TitlePage from './TitlePage';
import { GlobalContext } from '../context/GlobalContextProvider';

const addFigureId = (newHtml: string) => {
    const indexOfFigure = newHtml.indexOf('<div id="replace-figure-id">Figure #</div>');
    const htmlBeforeFigure = newHtml.slice(0, indexOfFigure);
    const numberOfFiguresBefore = (htmlBeforeFigure.match(/<img/g) || []).length;
    return newHtml.replace(
        '<div id="replace-figure-id">Figure #</div>',
        `<b style="display: block;">Figure ${numberOfFiguresBefore + 1}</b>`,
    );
};

const addTableId = (newHtml: string) => {
    const indexOfTable = newHtml.indexOf('<strong id="replace-table-id">Table #</strong>');
    const htmlBeforeTable = newHtml.slice(0, indexOfTable);
    const numberOfTablesBefore = (htmlBeforeTable.match(/<table/g) || []).length;
    return newHtml.replace(
        '<strong id="replace-table-id">Table #</strong>',
        `<strong style="display: block;">Table ${numberOfTablesBefore + 1}</strong>`,
    );
};

const Editor = ({ setGlobalLoading }: { setGlobalLoading?: React.Dispatch<React.SetStateAction<boolean>> | null }) => {
    const context = useContext(GlobalContext);
    const { html, setHtml, editorLoaded, setEditorLoaded } = context;
    const editorRef = useRef<TinyMCEEditor | null>(null);

    const onChange = (a: string, editor: TinyMCEEditor) => {
        context.setPerformedSystemCheck(false);
        setHtml(html => {
            const figureIdNeeded = a.match(/<div id="replace-figure-id">Figure #<\/div>/g)?.length || 0;
            const tableIdNeeded = a.match(/<div id="replace-table-id">Table #<\/div>/g)?.length || 0;
            if (figureIdNeeded) a = addFigureId(a);
            if (tableIdNeeded) a = addTableId(a);
            // Prevent page deletion
            const numberOfPagesOld = html.match(/class="mceNonEditable page"/g)?.length || 0;
            const numberOfPagesNew = a.match(/class="mceNonEditable page"/g)?.length || 0;
            // console.log(a);
            if (numberOfPagesNew < numberOfPagesOld) {
                return html;
            } else return a;
        });
    };

    useEffect(() => context.setEditor(editorRef), []);

    useEffect(() => {
        if (editorLoaded) {
            // TODO: Fetch html from backend
            setHtml(
                ReactDOMServer.renderToString(
                    <div className="mceNonEditable">
                        <Page id="titlePage">
                            <TitlePage {...context.titlePage} />
                        </Page>
                        <Page id="abstractPage">
                            <AbstractPage />
                        </Page>
                        <Page id="contentPage">
                            <ContentPage title={context.titlePage.title} />
                        </Page>
                        <Page id="conclusionPage">
                            <ConclusionPage />
                        </Page>
                        <Page id="referencesPage">
                            <ReferencesPage />
                        </Page>
                    </div>,
                ),
            );
        }
    }, [editorLoaded]);
    useEffect(() => {
        const frame = document.querySelector('.tox-edit-area__iframe') as HTMLIFrameElement;
        frame?.contentWindow?.document?.getElementsByTagName('body')[0]?.setAttribute('contenteditable', 'false');
    }, [editorLoaded]);

    return (
        <>
            <div
                style={{
                    minHeight: '100%',
                }}
            >
                <Grammarly
                    clientId="client_1tM5vDouwEG99XbEwGxUHd"
                    config={{
                        documentDialect: 'british',
                        autocomplete: 'on',
                        activation: 'immediate',
                    }}
                >
                    <GrammarlyEditorPlugin
                        clientId="client_1tM5vDouwEG99XbEwGxUHd"
                        config={{
                            documentDialect: 'british',
                            autocomplete: 'on',
                            activation: 'immediate',
                        }}
                    >
                        <TinyEditor
                            onLoadContent={() => {
                                setEditorLoaded(true);
                                if (setGlobalLoading) {
                                    setGlobalLoading(false);
                                }
                            }}
                            value={html}
                            onInit={(evt, editor) => {
                                editorRef.current = editor;
                            }}
                            apiKey="amrh800coufj3vof6mnn3zjianzz98urgfdei60ab65gvf67"
                            onEditorChange={onChange}
                            init={{
                                height: 'calc(100vh - 64px)',
                                menubar: false,
                                plugins: [
                                    'save advlist autolink lists charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help noneditable pagebreak wordcount',
                                ],
                                toolbar:
                                    'pagebreak formatselect | ' +
                                    'bold italic | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent wordcount',
                                table_toolbar: 'tabledelete',
                                block_formats:
                                    'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5;',
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                content_style: `body { background-color: gray; font-family:"Times New Roman", Times, serif; font-size:12pt; display: flex; flex-direction: column; justify-content: center; align-items: center; } 
                            [contenteditable] {outline: none;} 
                            .mceEditable {outline: none} 
                            p {margin: 0; line-height: 200%;}
                            ${
                                context.activePaper?.type !== 'discussion' &&
                                `.page:not(#abstractPage) p {
                                text-indent: 30px;
                            }`
                            }
                            .page .no-indent {
                                text-indent: 0!important;
                            }   
                            h1, h2, h3, h4, h5, h6 {
                                margin: 0;
                                font-size: 12pt;
                            }
                            h1 {
                                text-align: center;
                                font-style: normal;
                            }
                            h3 {
                                font-style: italic;
                            }
                            h4 {
                                text-indent: 30px;
                                font-style: normal;
                            }
                            h5 {
                                text-indent: 30px;
                                font-style: italic;
                            }
                            #content_toolbar1 {
                                position: fixed;
                                top: 0px;
                            }
                            .highlight_invalid_between {
                                background-color: rgba(211, 84, 0, 0.5);
                                padding: 2px;
                                border-radius: 2px;
                            }
                            .highlight_invalid_start{
                                background-color: rgba(243, 156, 18, 0.5);
                                padding: 2px;
                                border-radius: 2px;
                            }
                            .mceNonEditable {outline: none}`,
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                pagebreak_separator: ReactDOMServer.renderToString(
                                    <div
                                        className="page-break"
                                        style={{ overflow: 'hidden', height: 0, pageBreakAfter: 'always' }}
                                    />,
                                ),
                                paste_as_text: true,
                                paste_preprocess: (plugin: any, args: any) => {
                                    args.content = `<p>${args.content}</p`;
                                },
                            }}
                        />
                    </GrammarlyEditorPlugin>
                </Grammarly>
            </div>
        </>
    );
};

export default Editor;
