import React, { useContext, useState } from 'react';

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Drawer, Typography, Divider, Button, Form, List, Space, Popover, Input } from 'antd';
import Search from 'antd/lib/transfer/search';

import Reference from './Reference';
import { NewReference, EditReference } from './References';
import { GlobalContext } from '../context/GlobalContextProvider';
import { generateReferenceString } from '../helpers/generateReference';
import { useReferences } from '../hooks';
import { handleError, referenceToBibTex } from '../utility';

export const ReferencePageDrawer = ({
    visible,
    setVisibility,
}: {
    visible: boolean;
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const context = useContext(GlobalContext);
    const [manualReferenceLoading, setManualReferenceLoading] = useState(false);
    const { refreshReferencesPage } = useReferences();
    const [showNewReference, setShowNewReference] = useState(false);
    const [showEditReference, setShowEditReference] = useState(false);
    const {
        convertBibTexToCitation,
        addReference,
        convertBibTexToReference,
        references,
        removeReference,
        setActiveReference,
    } = useReferences();

    const addReferenceSubmit = async (plainReference: string) => {
        try {
            setManualReferenceLoading(true);
            const bibTexResponse = await referenceToBibTex(plainReference!);
            const bibTex = bibTexResponse.output?.split('\n\n')[0];
            const reference = convertBibTexToReference(bibTex!);
            const inTextCitation = await convertBibTexToCitation(bibTex!);
            addReference({ rawCitation: inTextCitation, rawReference: reference, bibTex, source: 'manual' });
        } catch (err) {
            handleError(err);
        } finally {
            setManualReferenceLoading(false);
        }
    };

    return (
        <Drawer
            title="References"
            placement="right"
            onClose={() => {
                refreshReferencesPage();
                setVisibility(false);
            }}
            visible={visible}
            width={'75vw'}
            destroyOnClose={true}
        >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flexGrow: 1 }}>
                    <NewReference hideReferenceAdder={() => setShowNewReference(false)} />
                </div>
                {/* <Drawer
                title="Edit Reference"
                placement="right"
                onClose={() => setShowEditReference(false)}
                visible={showEditReference}
                width="25vw"
                destroyOnClose={true}
            >
                <EditReference hideReferenceEditor={() => setShowEditReference(false)} />
            </Drawer> */}
                <div style={{ width: '25vw', maxWidth: '25vw', marginLeft: '1.5rem' }}>
                    {/* <Button
                        type="primary"
                        block
                        onClick={() => setShowNewReference(true)}
                        style={{ marginBottom: '0.75rem' }}
                    >
                        Lookup Reference
                    </Button> */}
                    <Input.Search
                        placeholder="Direct paste APA reference"
                        enterButton="Add"
                        style={{ marginBottom: '0.75rem' }}
                        onSearch={addReferenceSubmit}
                        loading={manualReferenceLoading}
                    />
                    <Button block onClick={() => refreshReferencesPage()}>
                        Refresh References Page
                    </Button>
                    <Divider />
                    <List
                        dataSource={references}
                        renderItem={(reference: AutoAPA.Reference) => {
                            /* eslint-disable react/prop-types */
                            const rawReference = reference?.rawReference;
                            return (
                                <List.Item
                                    actions={[
                                        rawReference ? (
                                            <></>
                                        ) : (
                                            <Button
                                                key="edit-reference-btn"
                                                icon={<EditOutlined />}
                                                onClick={() => {
                                                    setActiveReference(reference);
                                                    setShowEditReference(true);
                                                }}
                                                shape="circle"
                                            />
                                        ),
                                        <Button
                                            key="delete-reference-btn"
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => removeReference(reference)}
                                            shape="circle"
                                        />,
                                    ]}
                                >
                                    <Reference reference={reference} showAddCitation={false} />
                                </List.Item>
                            );
                        }}
                    />
                </div>
            </div>
        </Drawer>
    );
};

export default ReferencePageDrawer;
